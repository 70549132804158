<template>
  <div class="min-h-12">
    <app-fade-transition>
      <ul v-show="data.length > 0" class="flex flex-wrap gap-x-6 gap-y-4">
        <li v-for="user in data" :key="user.id" class="flex gap-2">
          <app-user-avatar
            :avatar-url="user.avatar_url"
            :name="user.first_name"
            :size="48"
          />

          <div>
            <p class="font-semibold">
              {{ user.first_name }}
            </p>
            <p class="text-sm">
              <span class="inline-block h-2 w-2 rounded-full bg-success" />
              {{ $t("growth.contact_page.team_list.available_label") }}
            </p>
          </div>
        </li>
      </ul>
    </app-fade-transition>
  </div>
</template>

<script lang="ts" setup>
const { data } = useLazyFetch("/api/contact/users", {
  server: false,
  default: () => [],
});
</script>
