import type { Ref } from "vue";

import ContactPageDefaultContent from "~/pages/contact/_includes/components/content/contact-page-default-content.vue";
import type { ContactFlowMetadata } from "~~/model/analytics.model";

const resolvers = {
  COURSE_PROGRAM_DOWNLOAD: {
    check: (metadata) =>
      metadata.origin === "course" &&
      ["download_program_cta", "follow_up_program_cta"].includes(
        metadata.origin_page_action as string,
      ),
    component: defineAsyncComponent(
      () =>
        import(
          "../components/content/contact-page-course-program-download-content.vue"
        ),
    ),
  },
  BLOG_POST_RESOURCE_DOWNLOAD: {
    check: (metadata) =>
      metadata.origin === "blog" &&
      metadata.origin_page_action === "download_resource_cta",
    component: defineAsyncComponent(
      () =>
        import(
          "../components/content/contact-page-blog-post-resource-download-content.vue"
        ),
    ),
  },
  DEFAULT: {
    check: () => true,
    component: ContactPageDefaultContent,
  },
} satisfies Record<
  string,
  {
    check(metadata: ContactFlowMetadata): boolean;
    component: ReturnType<typeof defineAsyncComponent>;
  }
>;

export type ContactPageContentKey = keyof typeof resolvers;

export function useContactPageContent(metadata: Ref<ContactFlowMetadata>) {
  const resolver =
    Object.entries(resolvers).find(([, resolver]) =>
      resolver.check(metadata.value),
    )?.[1] ?? resolvers["DEFAULT"];

  return {
    component: resolver.component,
  };
}
