<template>
  <app-form-input-text
    v-model="input"
    :label="label"
    :required="required"
    :rules="{ phone: true }"
  >
    <template #prepend>
      <client-only>
        <app-form-country-select
          v-model="code"
          class="w-full h-full grid justify-end"
        />
      </client-only>
    </template>
  </app-form-input-text>
</template>

<script lang="ts" setup>
import parsePhoneNumber from "libphonenumber-js";
import type { TranslateResult } from "vue-i18n";

const model = defineModel({ default: "" });

defineProps<{
  label: TranslateResult;
  required?: boolean;
}>();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const code = ref<any>("FR");
const input = ref(model.value);

watchEffect(() => {
  input.value =
    parsePhoneNumber(input.value, code.value)?.formatNational() ?? input.value;
});

watch(input, (value) => {
  model.value = parsePhoneNumber(value, code.value)?.number ?? value;
});

defineRule("phone", () => {
  if (parsePhoneNumber(model.value, code.value)?.isValid()) return true;

  return "Non valid";
});
</script>
