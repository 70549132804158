<template>
  <listbox v-model="model" as="div" class="relative">
    <listbox-button
      class="relative before:-inset-1 before:content-[''] before:absolute hover:scale-[120%] transition"
    >
      <app-pop-transition mode="out-in">
        <span :key="selection?.flag" class="relative">
          {{ selection?.flag }}
        </span>
      </app-pop-transition>
    </listbox-button>

    <transition
      enter-active-class="transition"
      enter-from-class="opacity-0 translate-y-2"
      leave-active-class="transition"
      leave-to-class="opacity-0 translate-y-2"
    >
      <listbox-options
        class="absolute top-full z-50 min-w-[max(100%,300px)] p-1 cursor-pointer rounded bg-white shadow-lg outline-none"
      >
        <ul class="overflow-auto max-h-[300px]">
          <listbox-option
            v-for="country in countries"
            :key="country.code"
            v-slot="{ active, selected }"
            as="template"
            :value="country.code"
          >
            <li
              class="p-2 text-sm rounded flex items-center justify-between"
              :class="{
                'bg-primary-50 ': active,
                '!bg-primary-100 text-primary font-semibold': selected,
              }"
            >
              <div class="flex gap-2 items-center">
                {{ country.flag }}
                <span class="line-clamp-1">{{ country.name }}</span>
              </div>
              <span class="text-xs text-subtle">({{ country.dial_code }})</span>
            </li>
          </listbox-option>
        </ul>
      </listbox-options>
    </transition>
  </listbox>
</template>

<script lang="ts" setup>
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";

import countries from "../country-metadata.json";

const model = defineModel({ default: "FR" });

const selection = computed(() => countries.find((c) => c.code === model.value));
</script>
