<template>
  <contact-page-content
    class="max-w-[700px]"
    :title="$t('growth.contact_page.title')"
  >
    <template #subtitle>
      <p class="text-balance">
        {{ $t("growth.contact_page.subtitle") }}
      </p>

      <contact-page-team-list class="mt-8 hidden lg:block" />
    </template>

    <template #append>
      <p class="mb-4">
        {{ $t("brand.customer_logo_banner.default_title") }}
      </p>

      <ul class="mb-4 flex flex-wrap gap-x-8 gap-y-5 opacity-80 invert">
        <li v-for="name in displayedLogos" :key="name">
          <app-image
            :alt="name"
            :height="24"
            :src="`/images/logos/black-and-white/${name}.svg`"
          />
        </li>
      </ul>
    </template>
  </contact-page-content>
</template>

<script setup lang="ts">
import ContactPageTeamList from "~/pages/contact/_includes/components/contact-page-team-list.vue";
import ContactPageContent from "~/pages/contact/_includes/components/content/contact-page-content.vue";

const displayedLogos = [
  "loreal",
  "doctolib",
  "carrefour",
  "docaposte",
  "morning",
  "orange",
  "malt",
  "bnpp",
  "stellantis",
];
</script>
